<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.314"
    height="8.485"
    viewBox="0 0 11.314 8.485"
    :class="color"
  >
    <g transform="translate(-546.343 -1215.172)">
      <path
        class="a"
        d="M550.586,1223.657l-4.243-4.243,1.414-1.414,2.828,2.828,5.657-5.657,1.414,1.414Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: ["color"],
  name: "Checkmark",
};
</script>


<style lang="scss" scoped>
.white {
  .a {
    fill: white;
  }
}
.midnight {
  .a {
    fill: #2d323c;
  }
}
</style>