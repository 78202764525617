import { render, staticRenderFns } from "./Checkmark.vue?vue&type=template&id=cb2a4672&scoped=true&"
import script from "./Checkmark.vue?vue&type=script&lang=js&"
export * from "./Checkmark.vue?vue&type=script&lang=js&"
import style0 from "./Checkmark.vue?vue&type=style&index=0&id=cb2a4672&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb2a4672",
  null
  
)

export default component.exports