<template>
  <div class="event-players-list" v-if="loaded">
    <ul class="summary">
      <li class="max-spots">
        <TjingToolTip text="Max spots" :hideOnDesktop="true">
          <SpotsLeft color="grey" />
          <p>
            <strong>{{ eventData.maxSpots }}</strong>
            <span> Max {{ eventData.maxSpots == 1 ? "spot" : "spots" }}</span>
          </p>
        </TjingToolTip>
      </li>
      <li class="available-spots">
        <TjingToolTip text="Available spots" :hideOnDesktop="true">
          <SpotsLeft />
          <p>
            <strong>{{ eventData.maxSpots - eventData.playersCount }}</strong>
            <span>
              Available
              {{
                eventData.maxSpots - eventData.playersCount == 1
                  ? "spot"
                  : "spots"
              }}</span
            >
          </p>
        </TjingToolTip>
      </li>
      <li class="confirmed-players">
        <TjingToolTip text="Confirmed players" :hideOnDesktop="true">
          <ConfirmedPlayers />
          <p>
            <strong>{{ eventData.playersCount }}</strong>
            <span>
              Confirmed
              {{ eventData.playersCount == 1 ? "player" : "players" }}</span
            >
          </p>
        </TjingToolTip>
      </li>
      <li class="waiting-players">
        <TjingToolTip text="Waiting players" :hideOnDesktop="true">
          <WaitingList />
          <p>
            <strong>{{ waitingListLength }}</strong
            ><span>
              Waiting {{ waitingListLength == 1 ? "player" : "players" }}</span
            >
          </p>
        </TjingToolTip>
      </li>
      <li class="wildcards-left">
        <TjingToolTip text="Wildcards left" :hideOnDesktop="true">
          <Wildcard />
          <p>
            <strong>{{ wildCardsLeft }} </strong>
            <span
              >{{ wildCardsLeft == 1 ? "Wildcard" : "Wildcards" }} left</span
            >
          </p>
        </TjingToolTip>
      </li>
    </ul>
    <div class="players-list">
      <div class="header">
        <ConfirmedPlayers />
        <h5>Confirmed</h5>
        <span>({{ eventData.playersCount }})</span>
      </div>
      <div
        class="division"
        v-for="(division, divisionIndex) in eventData.tour.divisions"
        :class="{ first: divisionIndex == 0 }"
        :key="division.id"
      >
        <div class="division-title">
          <p>
            <strong>{{ division.name }}</strong> ({{
              playersInDivision(division.id).length
            }})
          </p>
        </div>
        <div class="division-header">
          <div class="columns">
            <div class="position"></div>
            <div
              class="player"
              @click="toggleSort('firstName')"
              :class="{ active: sortBy == 'firstName' }"
            >
              <span>Player</span>
            </div>
            <div
              class="club"
              @click="toggleSort('club')"
              :class="{ active: sortBy == 'club' }"
            >
              <span>Club</span>
            </div>
            <div
              class="pdga"
              @click="toggleSort('pdgaNumber')"
              :class="{ active: sortBy == 'pdgaNumber' }"
            >
              <span>PDGA</span>
            </div>
            <div
              class="rating"
              @click="toggleSort('pdgaRating')"
              :class="{ active: sortBy == 'pdgaRating' }"
            >
              <span>Rat</span>
            </div>
            <div
              class="paid"
              @click="toggleSort('hasPaid')"
              :class="{ active: sortBy == 'hasPaid' }"
            >
              <span>Paid</span>
            </div>
            <div
              class="registered"
              @click="toggleSort('createdAt')"
              :class="{ active: sortBy == 'createdAt' }"
            >
              <span>Reg</span>
            </div>
            <div
              class="wildcard"
              @click="toggleSort('isWildcard')"
              :class="{ active: sortBy == 'isWildcard' }"
            >
              <span>WC</span>
            </div>
          </div>
        </div>
        <div class="division-body">
          <div
            class="columns player"
            v-for="(player, playerIndex) in playersInDivision(division.id)"
            :key="player.id"
          >
            <div class="position">
              <p>{{ playerIndex + 1 }}</p>
            </div>
            <div class="player">
              <p>{{ player.user.firstName }} {{ player.user.lastName }}</p>
            </div>
            <div class="club">
              <p>{{ player.user.profile.club }}</p>
            </div>
            <div class="pdga">
              <a
                :href="
                  'https://www.pdga.com/player/' +
                  player.user.profile.pdgaNumber
                "
                target="_blank"
                ><p>{{ player.user.profile.pdgaNumber }}</p></a
              >
            </div>
            <div class="rating">
              <a
                :href="
                  'https://www.pdga.com/player/' +
                  player.user.profile.pdgaNumber +
                  '/details'
                "
                target="_blank"
                ><p>{{ player.user.profile.pdgaRating }}</p></a
              >
            </div>
            <div class="paid">
              <Checkmark v-if="player.hasPaid" />
            </div>
            <div class="registered">
              <p>{{ regDateFormat(player).short }}</p>
              <div class="details">
                <p>{{ regDateFormat(player).long }}</p>
              </div>
            </div>
            <div class="wildcard"><Checkmark v-if="player.isWildcard" /></div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="eventData.waitinglistType.type == 'COMBINED'"
      class="players-list"
    >
      <div class="header">
        <WaitingList />
        <h5>Waiting</h5>
        <span>({{ waitingListLength }})</span>
      </div>
      <div class="division combined first">
        <div class="division-title waiting">
          <p><strong>All divisions</strong> ({{ waitingListLength }})</p>
        </div>
        <div class="division-header">
          <div class="columns waiting">
            <div class="position"></div>
            <div
              class="player"
              @click="toggleSort('firstName')"
              :class="{ active: sortBy == 'firstName' }"
            >
              <span>Player</span>
            </div>

            <div
              class="combineddivision"
              @click="toggleSort('division')"
              :class="{ active: sortBy == 'division' }"
            >
              <span>Division</span>
            </div>
            <div
              class="club"
              @click="toggleSort('club')"
              :class="{ active: sortBy == 'club' }"
            >
              <span>Club</span>
            </div>
            <div
              class="pdga"
              @click="toggleSort('pdgaNumber')"
              :class="{ active: sortBy == 'pdgaNumber' }"
            >
              <span>PDGA</span>
            </div>
            <div
              class="rating"
              @click="toggleSort('pdgaRating')"
              :class="{ active: sortBy == 'pdgaRating' }"
            >
              <span>Rat</span>
            </div>
            <div
              class="paid"
              @click="toggleSort('hasPaid')"
              :class="{ active: sortBy == 'hasPaid' }"
            >
              <span>Paid</span>
            </div>
            <div
              class="registered"
              @click="toggleSort('createdAt')"
              :class="{ active: sortBy == 'createdAt' }"
            >
              <span>Reg</span>
            </div>
          </div>
        </div>
        <div class="division-body">
          <div
            class="columns player"
            v-for="(player, playerIndex) in combinedWaitingList"
            :key="player.id"
          >
            <div class="position">
              <p>{{ playerIndex + 1 }}</p>
            </div>
            <div class="player">
              <p>{{ player.user.firstName }} {{ player.user.lastName }}</p>
            </div>
            <div class="combineddivision">
              <p>{{ player.division.name }}</p>
            </div>
            <div class="club">
              <p>{{ player.user.profile.club }}</p>
            </div>
            <div class="pdga">
              <p>{{ player.user.profile.pdgaNumber }}</p>
            </div>
            <div class="rating">
              <p>{{ player.user.profile.pdgaRating }}</p>
            </div>
            <div class="paid">
              <Checkmark v-if="player.hasPaid" />
            </div>
            <div class="registered">
              <p>{{ regDateFormat(player).short }}</p>
              <div class="details">
                <p>{{ regDateFormat(player).long }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="players-list">
      <div class="header">
        <WaitingList />
        <h5>Waiting</h5>
        <span>({{ waitingListLength }})</span>
      </div>
      <div
        class="division"
        v-for="(division, divisionIndex) in eventData.tour.divisions"
        :class="{ first: divisionIndex == 0 }"
        :key="division.id"
      >
        <div class="division-title waiting">
          <p>
            <strong>{{ division.name }}</strong> ({{
              waitingPlayersInDivision(division.id).length
            }})
          </p>
        </div>
        <div class="division-header">
          <div class="columns waiting">
            <div class="position"></div>
            <div
              class="player"
              @click="toggleSort('firstName')"
              :class="{ active: sortBy == 'firstName' }"
            >
              <span>Player</span>
            </div>
            <div
              class="club"
              @click="toggleSort('club')"
              :class="{ active: sortBy == 'club' }"
            >
              <span>Club</span>
            </div>
            <div
              class="pdga"
              @click="toggleSort('pdgaNumber')"
              :class="{ active: sortBy == 'pdgaNumber' }"
            >
              <span>PDGA</span>
            </div>
            <div
              class="rating"
              @click="toggleSort('pdgaRating')"
              :class="{ active: sortBy == 'pdgaRating' }"
            >
              <span>Rat</span>
            </div>
            <div
              class="paid"
              @click="toggleSort('hasPaid')"
              :class="{ active: sortBy == 'hasPaid' }"
            >
              <span>Paid</span>
            </div>
            <div
              class="registered"
              @click="toggleSort('createdAt')"
              :class="{ active: sortBy == 'createdAt' }"
            >
              <span>Reg</span>
            </div>
          </div>
        </div>
        <div class="division-body">
          <div
            class="columns player"
            v-for="(player, playerIndex) in waitingPlayersInDivision(
              division.id
            )"
            :key="player.id"
          >
            <div class="position">
              <p>{{ playerIndex + 1 }}</p>
            </div>
            <div class="player">
              <p>{{ player.user.firstName }} {{ player.user.lastName }}</p>
            </div>
            <div class="club">
              <p>{{ player.user.profile.club }}</p>
            </div>
            <div class="pdga">
              <p>{{ player.user.profile.pdgaNumber }}</p>
            </div>
            <div class="rating">
              <p>{{ player.user.profile.pdgaRating }}</p>
            </div>
            <div class="paid">
              <Checkmark v-if="player.hasPaid" />
            </div>
            <div class="registered">
              <p>{{ regDateFormat(player).short }}</p>
              <div class="details">
                <p>{{ regDateFormat(player).long }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { orderBy } from "lodash";
import Wildcard from "@/assets/icons/Wildcard";
import Checkmark from "@/assets/icons/Checkmark";
import ConfirmedPlayers from "@/assets/icons/ConfirmedPlayers";
import WaitingList from "@/assets/icons/WaitingList";
import SpotsLeft from "@/assets/icons/SpotsLeft";

export default {
  name: "PlayerList",
  data: function () {
    return {
      eventData: null,
      loaded: false,
      sortBy: "createdAt",
      sortDirection: "asc",
    };
  },
  components: {
    SpotsLeft,
    ConfirmedPlayers,
    WaitingList,
    Wildcard,
    Checkmark,
  },
  computed: {
    combinedWaitingList() {
      let allWaitingPlayers = [];
      const sortOrder = this.sortDirection;
      const orderingBy = this.sortBy;

      this.eventData.registrations.forEach((registration) => {
        if (registration.status == "PENDING") {
          allWaitingPlayers.push(registration);
        }
      });

      allWaitingPlayers.forEach((waitingplayer) => {
        this.eventData.players.forEach((player) => {
          if (waitingplayer.user.id == player.userId) {
            waitingplayer.hasPaid = player.hasPaid;
          }
        });
      });

      return orderBy(
        allWaitingPlayers,
        [
          (o) => {
            if (orderingBy == "firstName") return o.user.firstName;
            if (orderingBy == "division") return o.division.name;
            if (orderingBy == "club") return o.user.profile.club;
            if (orderingBy == "pdgaNumber")
              return o.user.profile.pdgaNumber || "";
            if (orderingBy == "pdgaRating")
              return o.user.profile.pdgaRating || "";
            if (orderingBy == "createdAt") return o.createdAt;
            if (orderingBy == "hasPaid") return o.hasPaid;
          },
        ],
        [sortOrder]
      );
    },
    wildCardsLeft() {
      const players = this.eventData.players;
      const maxWildcards = this.eventData.maxWildcards;
      let wildCardsLeft = 0;
      let wildCardOunter = 0;

      players.forEach((player) => {
        if (player.isWildcard) {
          wildCardOunter++;
        }
      });

      if (maxWildcards - wildCardOunter > 0) {
        wildCardsLeft = maxWildcards - wildCardOunter;
      }

      return wildCardsLeft;
    },
    waitingListLength() {
      const registrations = this.eventData.registrations;
      let waitingListLength = 0;

      waitingListLength = registrations.filter(
        (registration) => registration.status == "PENDING"
      ).length;

      return waitingListLength;
    },
  },
  methods: {
    toggleSort(property) {
      if (this.sortBy == property) {
        if (this.sortDirection == "desc") this.sortDirection = "asc";
        else this.sortDirection = "desc";
      }
      this.sortBy = property;
    },
    regDateFormat(player) {
      const playerRegistration = this.eventData.registrations.find(
        (reg) => reg.user.id == player.user.id
      );

      return {
        short: moment(playerRegistration?.createdAt).format("D MMM"),
        long: moment(playerRegistration?.createdAt).format("D MMM HH:mm:ss"),
      };
    },
    waitingPlayersInDivision(divisionId) {
      const players = this.eventData.registrations.filter(
        (reg) => reg.status == "PENDING"
      );
      const sortOrder = this.sortDirection;
      const orderingBy = this.sortBy;
      let filterProperty = "";
      let playersInDivision = players.filter(
        (player) => player.division.id == divisionId
      );

      playersInDivision.forEach((waitingplayer) => {
        this.eventData.players.forEach((player) => {
          if (waitingplayer.user.id == player.userId) {
            waitingplayer.hasPaid = player.hasPaid;
          }
        });
      });

      return orderBy(
        playersInDivision,
        [
          (o) => {
            if (orderingBy == "firstName") return o.user.firstName;
            if (orderingBy == "club") return o.user.profile.club;
            if (orderingBy == "pdgaNumber")
              return o.user.profile.pdgaNumber || "";
            if (orderingBy == "pdgaRating")
              return o.user.profile.pdgaRating || "";
            if (orderingBy == "createdAt") return o.createdAt;
            if (orderingBy == "hasPaid") return o.hasPaid;
          },
        ],
        [sortOrder]
      );
    },
    playersInDivision(divisionId) {
      const players = this.eventData.players;
      const sortOrder = this.sortDirection;
      const orderingBy = this.sortBy;
      let filterProperty = "";
      let playersInDivision = players.filter(
        (player) => player.division.id == divisionId
      );

      playersInDivision.forEach((player) => {
        this.eventData.registrations.forEach((reg) => {
          if (reg.user.id == player.userId) {
            player.createdAt = reg.createdAt;
          }
        });
      });

      return orderBy(
        playersInDivision,
        [
          (o) => {
            if (orderingBy == "firstName") return o.user.firstName;
            if (orderingBy == "club") return o.user.profile.club;
            if (orderingBy == "pdgaNumber")
              return o.user.profile.pdgaNumber || "";
            if (orderingBy == "pdgaRating")
              return o.user.profile.pdgaRating || "";
            if (orderingBy == "createdAt") return o.createdAt;
            if (orderingBy == "isWildcard") return o.isWildcard;
            if (orderingBy == "hasPaid") return o.hasPaid;
          },
        ],
        [sortOrder]
      );
    },
    async loadData() {
      let eventData = await this.$axios({
        data: {
          query: `
          {
            event(eventId:"${this.$router.currentRoute.params.eventId}"){
              tour{
                id
                divisions{
                  id
                  type
                  position
                  name
                }
              }
              id
              groupSize
              maxSpots
              registrationsCount
              playersCount
              maxWildcards
              isRegistrationOpen
              registrationOpenAt
              status
              waitinglistType{
                name
                type
              }
              players{
                id
                userId
                onSite
                isWildcard
                hasPaid
                user{
                  id
                  firstName
                  lastName
                  profile{
                    id
                    club
                    pdgaNumber
                    pdgaRating
                    country{
                      name
                      code
                    }
                    phone
                  }
                }
                division{
                  id
                }
              }
              registrations{
                id
                status
                createdAt
                hasPaid
                user{
                  id
                  firstName
                  lastName
                  profile{
                    id
                    club
                    pdgaNumber
                    pdgaRating
                    country{
                      name
                      code
                    }
                    phone
                  }
                }
                division{
                  id
                  name
                }
              }
            }
          }
          `,
        },
      });

      this.eventData = eventData.data.data.event;
      this.loaded = true;
    },
  },
  beforeMount() {
    this.loadData();
    this.$store.dispatch("updateUserEventInformation");
  },
};
</script>

<style scoped lang="scss">
.event-players-list {
  padding: 0 12px;
}
.summary {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 16px;
  margin: 0;
  border: 1px solid $fog;
  border-radius: 10px;

  li {
    display: flex;
    align-items: center;
    position: relative;
    > div {
      display: flex;
      align-items: center;
    }
    svg {
      margin-right: 6px;
      height: 16px;
      width: 16px;
    }
    p {
      line-height: 1rem;
      color: $blizzard;
      margin: 0;
      strong {
        @include Gilroy-Bold;
      }
      span {
        display: none;
      }
    }
    &:hover {
      &::after {
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
        background: $midnight;
        height: 28px;
        font-size: 12px;
        white-space: nowrap;
        padding: 0 10px;
        border-radius: 14px;
        color: white;
        display: flex;
        align-items: center;
        @include Gilroy-Bold;
        letter-spacing: 0.2px;
      }
    }
  }
}
.players-list {
  margin-top: 32px;

  .header {
    display: flex;
    align-items: center;
    padding-left: 6px;
    width: 100%;
    justify-content: flex-start;

    h5 {
      font-size: 14px;
      margin: 0;
      @include Gilroy-Bold;
    }
    span {
      padding-left: 4px;
      font-size: 14px;
      @include Gilroy-Regular;
    }
    svg {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }
  }
  .division {
    position: relative;
    margin-top: 24px;

    &.combined {
      .division-header {
        .columns {
          .combineddivision {
            display: none;
          }
        }
      }
      .division-body {
        .columns {
          .combineddivision {
            display: none;
          }
        }
      }
    }
    .division-title {
      position: sticky;
      z-index: 10;
      top: 0;
      background: #e5f8f8;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px;
      border-radius: 6px;

      &.waiting {
        background: #fff0ec;
      }

      p {
        font-size: 14px;
        margin: 0;
        strong {
          @include Gilroy-Bold;
        }
      }
      .reserved {
        font-size: 10px;
        margin-left: auto;
      }
    }

    .division-header {
      background: white;

      .columns {
        margin-top: 16px;
        display: flex;

        &.waiting {
          div {
            &.active {
              span {
                border-bottom: 2px solid $sunset;
              }
            }
          }
        }

        div {
          display: flex;
          font-size: 10px;
          @include Gilroy-Bold;
          text-transform: uppercase;
          justify-content: center;
          padding-bottom: 8px;
          span {
            cursor: pointer;
            border-bottom: 2px solid transparent;
            user-select: none;
          }
          &.active {
            span {
              border-bottom: 2px solid $ocean;
            }
          }
        }
        .position {
          flex: 0.5;
          max-width: 50px;
        }
        .player {
          justify-content: left;
          width: 45%;
        }
        .club {
          display: none;
        }
        .pdga {
          flex: 1;
        }
        .rating {
          flex: 1;
        }
        .paid {
          flex: 1;
        }
        .registered {
          display: none;
        }
        .wildcard {
          display: none;
        }
      }
    }
    .division-body {
      .columns {
        display: flex;
        border-bottom: 1px solid $fog;
        div {
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            font-size: 14px;
            margin: 0;
          }
        }

        a:hover {
          p {
            text-decoration: underline;
          }
        }
        .position {
          flex: 0.5;
          max-width: 50px;
        }
        .player {
          justify-content: flex-start;
          width: 45%;

          p {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            @include Gilroy-Bold;
          }
        }

        .club {
          display: none;
          p {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        .pdga {
          flex: 1;
        }
        .rating {
          flex: 1;
        }
        .paid {
          flex: 1;
        }
        .registered {
          display: none;
        }
        .wildcard {
          display: none;
        }
      }
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
  .players-list {
    margin-top: 36px;
    .division {
      margin-top: 42px;

      &.first {
        margin-top: 16px;
      }

      &.combined {
        .division-header {
          .columns {
            .player {
              width: 25%;
            }
            .combineddivision {
              width: 25%;
              justify-content: flex-start;
              display: flex;
            }
            .club {
              display: none;
            }
          }
        }
        .division-body {
          .columns {
            .player {
              width: 25%;
            }
            .club {
              display: none;
            }
            .combineddivision {
              width: 25%;
              justify-content: flex-start;
              display: flex;
            }
          }
        }
      }
      .division-header {
        .columns {
          .position {
            flex: 0.8;
          }
          .player {
            width: 30%;
            padding-right: 10px;
          }
          .club {
            display: flex;
            justify-content: flex-start;
            width: 25%;
            p {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
          .pdga {
            flex: 1;
          }
          .rating {
            flex: 1;
          }
          .paid {
            flex: 1;
          }
          .registered {
            display: flex;
            flex: 1;
            justify-content: center;
          }
          .wildcard {
            display: flex;
            flex: 1;
          }
        }
      }
      .division-body {
        .columns {
          .position {
            flex: 0.8;
          }
          .player {
            width: 30%;
            padding-right: 10px;
          }
          .club {
            display: flex;
            width: 25%;
            padding-right: 10px;
            justify-content: flex-start;
          }
          .pdga {
            flex: 1;
          }
          .rating {
            flex: 1;
          }
          .paid {
            flex: 1;
          }
          .registered {
            display: flex;
            flex: 1;
            position: relative;

            &:hover {
              overflow: visible;

              .details {
                display: flex;
              }
            }

            .details {
              display: none;
              position: absolute;
              top: -25px;
              left: 50%;
              transform: translateX(-50%);
              height: 30px;
              background: $midnight;
              color: white;
              white-space: nowrap;
              z-index: 1;
              padding: 6px 10px;
              border-radius: 15px;
              p {
                font-size: 12px;
                @include Gilroy-Bold;
              }
            }
          }
          .wildcard {
            display: flex;
            flex: 1;
          }
        }
      }
    }
  }
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
  .event-players-list {
    padding: 0;
  }
  .summary {
    padding: 0;
    border: none;
    border-radius: 0;

    li {
      svg {
        margin-right: 10px;
        height: 20px;
        width: 20px;
      }
      p {
        line-height: 1.1rem;
        color: $strom;
        margin: 0;
        font-size: 16px;

        span {
          display: inline;
        }
      }

      &.wildcards-left {
        &:hover {
          &::after {
            transform: translateX(-50%);
          }
        }
      }
    }
  }
  .players-list {
    margin-top: 64px;
    .header {
      svg {
        margin-right: 10px;
        height: 24px;
        width: 24px;
      }
      h5,
      span {
        font-size: 18px;
      }
    }

    .division {
      &.first {
        margin-top: 24px;
      }

      &.combined {
        .division-header {
          .columns {
            .player {
              width: 25%;
            }
            .combineddivision {
              width: 15%;
              justify-content: flex-start;
              display: flex;
            }
            .club {
              display: flex;
              width: 20%;
            }
          }
        }
        .division-body {
          .columns {
            .player {
              width: 25%;
            }
            .club {
              display: flex;
              width: 20%;
            }
            .combineddivision {
              width: 15%;
              justify-content: flex-start;
              display: flex;
            }
          }
        }
      }

      .division-body {
        .columns {
          &:last-of-type {
            border-bottom: 0;
          }
          div {
            height: 56px;
            p {
              font-size: 16px;
            }
          }
          .club {
            justify-content: flex-start;
          }
          .pdga,
          .paid,
          .rating {
            flex: 1.5;
          }
          .registered {
            &:hover {
              .details {
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
      }
      .division-title {
        padding: 10px 16px;
        p {
          strong {
            font-size: 16px;
          }
        }
        .reserved {
          font-size: 14px;
        }
      }
      .division-header {
        .columns {
          div {
            margin-top: 8px;
            font-size: 14px;
          }

          .club {
            justify-content: flex-start;
          }
          .pdga,
          .paid,
          .rating {
            flex: 1.5;
          }
        }
      }
    }
  }
}
</style>

